import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "../component/Login";
import CommonLayout from "../common/CommonLayout";
import ForgotPassword from "../component/ForgotPassword";
import GeneratePassword from "../component/GeneratePassword";
import Register from "../component/Register";
import Home from "../component/Home";
import Creator from "../component/Creator";
import Brands from "../component/Brands";
import LaunchForm from "../component/LaunchForm";
import Marketplace from "../component/Marketplace";
import CreatorProfile from "../component/CreaterProfile";
import UpdateProfilePage from "../component/UpdateProfilePage";
import ContactUs from "../component/ContactUs";
import Blog from "../component/Blog";

const Routing = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={<CommonLayout>{<Home />}</CommonLayout>}
        ></Route>
         <Route
          path="/creator"
          element={<CommonLayout>{<Creator />}</CommonLayout>}
        ></Route>
         <Route
          path="/brands"
          element={<CommonLayout>{<Brands />}</CommonLayout>}
        ></Route>
         <Route
          path="/marketplace"
          element={<CommonLayout>{<Marketplace />}</CommonLayout>}
        ></Route>
          <Route
          path="/contact-us"
          element={<CommonLayout>{<ContactUs />}</CommonLayout>}
        ></Route>
            <Route
          path="/creator-profile"
          element={<CommonLayout>{<CreatorProfile />}</CommonLayout>}
        ></Route>
        <Route
          path="/update-profile"
          element={<CommonLayout>{<UpdateProfilePage />}</CommonLayout>}
        ></Route>
        <Route
          path="/login"
          element={<Login />}
        ></Route>
        <Route
          path="/forgot-password"
          element={<ForgotPassword />}
        ></Route>
        <Route
          path="/reset-password/:token/:role"
          element={<GeneratePassword />}
        ></Route>
         <Route
          path="/register"
          element={<Register />}
        ></Route>
            <Route
          path="/blogs"
          element={<CommonLayout>{<Blog />}</CommonLayout>}
        ></Route>
      </Routes>
    </Router>
  );
};

export default Routing;
